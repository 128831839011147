<template>
  <div class="page-auth">
    <div class="layer">
      <v-container>
        <v-row align="center"
               class="mt-16 pt-12"
               justify="center">

          <v-col cols="11"
                 lg="4"
                 md="4"
                 sm="8">

            <v-card flat>
              <v-card-text class="pa-6">

                <h1 class="text-center font-weight-medium py-5">
                 Connexion
                </h1>

                <p class="py-4 text-center">
                  Connectez-vous pour continuer.
                </p>

                <v-alert v-if="errors && errors.invalid"
                         border="bottom"
                         class="fs-14"
                         color="red"
                         dark
                         dense
                         dismissible
                         icon="mdi-alert"
                         prominent>
                  {{ errors && errors.invalid ? errors.invalid : '' }}
                </v-alert>


                <form @submit.prevent="login">

                  <v-text-field v-model="form.email"
                                :error-messages="errors.email"
                                class="inp"
                                dense
                                outlined
                                placeholder="Email"
                                prepend-inner-icon="mdi-email-outline"
                                type="email"
                                @input='errors && errors.email ? errors.email= "": false'></v-text-field>


                  <v-text-field v-model="form.password"
                                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                                :error-messages="errors.password"
                                :type="show ? 'text' : 'password'"
                                class="inp"
                                dense
                                hide-details="auto"
                                outlined
                                placeholder="Mot de passe"
                                prepend-inner-icon="mdi-lock-outline"
                                @input='errors && errors.password ? errors.password= "": false'
                                @click:append="show = !show"></v-text-field>

                  <v-checkbox label="Se souvenir de moi"></v-checkbox>

                  <v-btn :loading="btnLoading"
                         :style="{transition: '.8s'}"
                         :large="$vuetify.breakpoint.mobile"
                         block
                         color="primary"
                         depressed
                         type="submit">
                    Connexion
                  </v-btn>
                </form>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <div class="mt-15 white--text  text-center">
          Copyright © {{ (new Date()).getFullYear() }}
          <a class="text-decoration-none  secondary--text" href="https://STORE-EXECUTION-dz.com" target="_blank">Store
            Execution</a>, All rights Reserved
        </div>

      </v-container>
    </div>
  </div>
</template>

<script>


import axios from "axios";

export default {

    data() {
        return {
            fullscreen: true,
            btnLoading: false,
            errors: {},
            form: {},
            show: false
        }
    },
    methods: {
        login() {
            this.btnLoading = true
            this.errors = {}
            axios.post(process.env.VUE_APP_BASE_URL + '/api/login', this.form).then((res) => {
                this.$store.dispatch('isLoggedIn', true)
                this.$store.dispatch('user', res.data.data)
                this.btnLoading = false
                this.$router.go(0);
            }).catch(err => {
                this.btnLoading = false
                this.errors = err.response.data.errors;
                this.$errorMessage = "Une erreur ç'est produit veuillez le corriger."
                console.log(err)
            })
        }
    }

}
</script>

<style>
.page-auth {
    background: url('../../assets/auth.jpg');
    min-height: 100vh;
    background-size: cover;
}

.layer {
    background-color: rgba(23, 70, 139, 0.9);
    min-height: 100vh;
}

.theme--light.v-icon {
    color: #808897;
}
</style>
